<template>
  <div class="settings BG0 relative z-[2000]" :style="{border: '1px solid #e6e8f0', width:store.showAll ? '286px':''}">
    <div class="cursor-pointer relative ">
      <a
        @click="routeUrl()"
        class="flex justify-center gap-2"
      >
        <div class="mt-2">
          <svg
            width="35"
            height="29"
            viewBox="0 0 41 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="40.4" height="5.77143" rx="2.88571" fill="#DD5928" />
            <rect
              y="11.543"
              width="40.4"
              height="5.77143"
              rx="2.88571"
              fill="#DD5928"
            />
            <rect
              y="23.0859"
              width="40.4"
              height="5.77143"
              rx="2.88571"
              fill="#DD5928"
            />
          </svg>
        </div>
        <p
          class="
            mt-2
            text-[27px]
            N700
            font-bold
            sm:hidden
            md:hidden
            lg:block
            xl:block
          "
          v-if="store.showAll"
        >
          AmaliTech
        </p></a
      >
      <span
        class="
          absolute
          h-6
          w-6
          rounded-full
          flex
          items-center
          py-0
          px-2
          shadow-md
          BG0
          top-10
          -right-5
        "
        @click="store.showAll = !store.showAll"
      >
        <ArrowLeftIcon v-if="store.showAll" />
        <ArrowRightIcon v-else />
      </span>
    </div>
    <div class="flex flex-col h-[20vh]">
      <div class="pt-5 px-3">
        <hr />
      </div>
      

      <div class="p-5">
        <div>
          <NavigationComponent
            :item="{ label: 'Your loans', route: '/' }"
            @mouseover="toggleOnYourLoans(true)"
            @mouseout="toggleOnYourLoans(false)"
          >
            <LoansSvg
              :color="
                onYourLoans || $route.path === '/' ? '#DD5928' : '#8F95B2'
              "
            />
          </NavigationComponent>

          <NavigationComponent
            v-if="accesslevel.permissions.manage_loans_manager"
            :item="{ label: 'Manage loans', route: '/manage-loans' }"
            @mouseover="toggleOnManageLoans(true)"
            @mouseout="toggleOnManageLoans(false)"
          >
            <MyWallet
              :color="
                onManageLoans || $route.path === '/manage-loans'
                  ? '#DD5928'
                  : '#8F95B2'
              "
            />
          </NavigationComponent>
        </div>
      </div>

      <div class="pt-5 px-3">
        <hr />
      </div>

      <div class="p-5">
        <NavigationComponent
          v-if="accesslevel.permissions.manage_loan_forms || accesslevel.permissions.manage_access_level ||
           accesslevel.permissions.manage_loan_approvers || accesslevel.permissions.manage_loan_types
          "
          :item="{ label: 'Settings', route: '/set-loan-forms' }"
          @mouseover="toggleOnSettings(true)"
          @mouseout="toggleOnSettings(false)"
        >
          <SettingsSVG
            :color="
              onSettings ||
              $route.path === '/set-loan-approver' ||
              $route.path === '/set-loan-forms' ||
              $route.path === '/loan-type-overview' ||
              $route.path === '/accesslevel'
                ? '#DD5928'
                : '#8F95B2'
            "
          />
        </NavigationComponent>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import SettingsSVG from "@/assets/SettingsIcon.vue";
import LoansSvg from "@/assets/LoansSvg.vue";
import NavigationComponent from "./NavigationComponent.vue";
import { ref } from "vue";
import { useStore } from "../store";
import { encodeUrlPath } from "../helpers/utils";
import MyWallet from "@/components/assets/Mywallet.vue";
import { getToken } from "@/services/auth";
import config from "@/config/config";
import ArrowLeftIcon from "@/assets/ArrowLeftIcon.vue";
import ArrowRightIcon from "@/assets/ArrowRightIcon.vue";
import { useAccessLevelStore } from "@/Loans-store/store_access_level";

const emits = defineEmits(["appSelected"]);

const store = useStore();
const user_id = ref(store.user.id);
let encryptedId = encodeUrlPath(user_id.value?.toString());
let accesslevel = useAccessLevelStore();

const env = process.env;

const onYourLoans = ref(false);
const onManageLoans = ref(false);
const onSettings = ref(false);

const toggleOnYourLoans = (status: boolean) => {
  onYourLoans.value = status;
};

const toggleOnManageLoans = (status: boolean) => {
  onManageLoans.value = status;
};

const toggleOnSettings = (status: boolean) => {
  onSettings.value = status;
};

const loginHint: string = store.usersHint;
const routeUrl = () => {
  const token: string | null = getToken();
  if (token) {
    window.location.href = `${config.mainPageLink}`;
  }
};
</script>
<style scoped>
.settings {
  height: 100vh;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  color: #474d66;
  background-color: white;
  overflow-y: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.settings::-webkit-scrollbar {
  display: none;
}

ul {
  margin: 19px 0;
}

li {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.H700 {
  z-index: 2;
}

@media screen and (min-width: 670px) and (max-width: 768px) {
  .menu {
    padding: 10px;
  }
}

@media screen and (max-width: 670px) {
  .menu {
    position: relative;
    top: 0;
    height: 40px;
    left: 5px;
    border: 1px solid #d8dae5;
    border-radius: 8px;
  }

  .menu_view {
    width: auto;
  }
}
</style>
