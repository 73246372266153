<template>
  <div
    class="flex justify-between content-center N700 mb-8 pr-10"
    @mouseleave="removeDropdowns"
  >
    <div class="SPC-MT-100 flex"></div>

    <div class="flex gap-4">
      <div
        class="w-[88px] self-center cursor-pointer p-2 bg-[#C1C4D6] rounded-md flex gap-1"
        @click="showAppsDropdown = !showAppsDropdown"
      >
        <img src="../assets/menu-icons/apps.svg" class="appsIcon" alt="appicon"/>
         <p>Apps</p>
      </div>
       <div
        class="
          self-center
          cursor-pointer
          bg-[#D8DAE5]
          p-2
          rounded-lg
          flex
          gap-2
        "
        @click="redirectToHelp"
      >
        <img src="../components/assets/Help.svg" alt="help"/>
      </div>
      <div
        class="flex gap-4"
        @click="showProfiledropdown = !showProfiledropdown"
      >
        <ProfileImageComponent
          :imgSrc="profilePicture"
          :initials="nameToInit(initials)"
        />
        <p class="self-center N800 username">{{ username }}</p>
        <img
          src="@/assets/arrow-down.svg"
          class="cursor-pointer h-fit self-center"
          alt="arrow-down"
        />
      </div>
      <ProfileDropdown v-if="showProfiledropdown" />
      <AppsNavigation v-if="showAppsDropdown" />
    </div>
  </div>

  <!--handle breadcrumbs here-->
  <div class="w-full px-5">
    <!--banner handled here-->
    <div class="mb-3">
      <PageBanner
        v-if="trackRoute === 'LoanForms'"
        title="Setup loan form for your organization."
        anchorText=" Learn more about setting up loan form."
      />

      <PageBanner
        v-if="trackRoute === 'LoanType'"
        title="Setup loan form for your organization."
        anchorText="Learn more about setting up loan type."
      />
    </div>

    <div  v-if="trackRoute === 'ManageLoans'" class="-mb-10">
      <BreadCrumbsComponent
        v-if="trackRoute === 'ManageLoans'"
        :breadcrumbs="breadcrumbmanageloans"
      />
    </div>

    <BreadCrumbsComponent
      v-if="trackRoute === 'LoanForms'"
      :breadcrumbs="breadcrumbsloanforms"
    />

    <BreadCrumbsComponent
      v-if="trackRoute === 'SetLoanApprover'"
      :breadcrumbs="breadcrumbsloanapprovers"
    />

    <BreadCrumbsComponent
      v-if="trackRoute === 'LoanType'"
      :breadcrumbs="breadcrumbsloanrtypes"
    />

    <div v-if="trackRoute === 'LoanDetails'" class="-mb-10">
      <BreadCrumbsComponent
       v-if="trackRoute === 'LoanDetails'"
      :breadcrumbs="breadcrumbloandetails"
    />
    </div>

    <div
     v-if="trackRoute === 'ViewLoanApplication'"
     class="-mb-10"
    >
      <BreadCrumbsComponent
      v-if="trackRoute === 'ViewLoanApplication'"
      :breadcrumbs="breadcrumbspersonalloans"
    />
    </div>

    <BreadCrumbsComponent
      v-if="trackRoute === 'accessLevel'"
      :breadcrumbs="breadcrumbaccesslevel"
    />

    <BreadCrumbsComponent
      v-if="trackRoute === 'accessLevelsub'"
      :breadcrumbs="breadcrumbaccesssublevel"
    />
  </div>
</template>
<script setup lang="ts">
import { computed, ref } from "vue";
import { useStore } from "@/store/index";
import ProfileDropdown from "./ProfileDropdown.vue";
import AppsNavigation from "./AppsNavigation.vue";
import ProfileImageComponent from "@/ui-kit/components/Avatar/ProfileImageComponent.vue";
import { nameToInit } from "@/helpers/utils";
import { useRoute } from "vue-router";
import BreadCrumbsComponent from "@/ui-kit/bread-crumbs/BreadCrumbsComponent.vue";
import PageBanner from "@/components/reusables/PageBanner.vue";
import router from "@/router";

const store = useStore();
const username = computed(()=>(store.user.first_name));
const initials = computed(()=>(store.user.first_name+" "+store.user.last_name));
const profilePicture = computed(()=>store.user.profile_image);
const route = useRoute();

const showProfiledropdown = ref<boolean>(false);
const showAppsDropdown = ref<boolean>(false);
const removeDropdowns = () => {
  showAppsDropdown.value = false;
  showProfiledropdown.value = false;
};

//habdle breadcrumb here
const trackRoute = computed(() => {
  return route.name;
});

const breadcrumbmanageloans = ref([
  { label: "Home", to: "/" },
  { label: "Manage loans", to: "/manage-loans" }
]);

const breadcrumbsloanforms = ref([
  { label: "Home", to: "/" },
  { label: "settings", to: "/set-loan-forms" },
  { label: "Loan forms", to: "/set-loan-forms" }
]);

const breadcrumbsloanrtypes = ref([
  { label: "Home", to: "/" },
  { label: "settings", to: "/set-loan-forms" },
  { label: "Loan types", to: "/loan-type-overview" }
]);

const breadcrumbsloanapprovers = ref([
  { label: "Home", to: "/" },
  { label: "settings", to: "/set-loan-forms" },
  { label: "Loan approvers", to: "/loan-type-overview" }
]);

const breadcrumbloandetails = ref([
  { label: "Home", to: "/" },
  { label: "Manage loans", to: "/manage-loans" },
  { label: "Loans details", to: "/loan-details" }
]);

const breadcrumbspersonalloans = ref([
  { label: "Home", to: "/" },
  { label: "View loans application", to: "/view-loan-details" }
]);

const breadcrumbaccesslevel = ref([
  { label: "Home", to: "/" },
  { label: "settings", to: "/set-loan-forms" },
  { label: "Access level", to: "/accesslevel" }
]);

const breadcrumbaccesssublevel = ref([
  { label: "Home", to: "/" },
  { label: "settings", to: "/set-loan-forms" },
  { label: "Access level", to: "/accesslevel" },
  { label: "Create access level", to: "/accesslevelsub" }
]);

const redirectToHelp = () => {
  router.push("/loans-help")
};
</script>
<style>
@media (max-width: 420px) {
  .profile-img {
    border-radius: 50%;
  }

  .username {
    display: none;
  }
}
</style>
