<template>
  <div v-if="showBanner" class="page lg:h-[3rem]">
    <div class="banner">
      <div class="pt-1 SPC-MR-200 max-sm:pt-[28px] sm:pt-[28px] lg:pt-[4px]">
        <InfoClose />
      </div>

      <div class="P200">
        <span class="N800">{{ title }}</span>
        <span class="O400">
          <a href="/#">{{ anchorText }}</a></span
        >
      </div>
    </div>
    <button @click="showBanner = false" type="button" class="">
      <CloseCircle />
    </button>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import InfoClose from "../assets/svg/svgComponents/InfoClose.vue";
import CloseCircle from "../assets/svg/svgComponents/CloseCircle.vue";

let showBanner = ref(true);

defineProps({
  title: {
    type: String,
    default: "text"
  },
  anchorText: {
    type: String,
    default: "text"
  }
});
</script>

<style scoped>
.banner {
  display: flex;
}
.page {
  background: #fafbff;
  border: 1px solid #e6e8f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  padding: 0 1.938rem;
}
@media only screen and (max-width: 1000px) and (min-width: 0px) {
  .page {
    display: none;
  }
}
</style>
