import {AppType} from "../types/appsNavTypes";
import { UserType } from "@/types/usersType";
import jwtDecode from "jwt-decode";

/**
 * Encrypts the given ID and returns the encoded path.
 *
 * @param id The ID to be encrypted.
 * @returns The encoded path.
 */
const env = process.env;
const key = String(env.VUE_APP_ENCRYPTION_KEY);


export const encodeUrlPath = (id: string): string => {
  const path = `${key}${id}`;
  return encodeURIComponent(btoa(path));
};

/**
 * Decrypts the encoded URL and returns the decrypted value.
 *
 * @param {string} encodedUrl - The URL encoded string to be decrypted.
 * @return {string} The decrypted URL value.d
 */
export const decodeUrlPath = (encodedUrl: string | string[]): string => {
  const path = atob(decodeURIComponent(encodedUrl as string));
  const id = path.split(key)[1];
  return id;
};

export const formatTime = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
    .toString()
    .padStart(2, "0")}`;
};

export function nameToInit(name?: string): string {
  const n = name?.split(" ");
  let s = "";
  if (n) {
    for (const part of n) {
      if (part[0]) s += part[0].toUpperCase();
    }
  }
  return s.slice(0, 2);
}


export const truncateText = (text: string, to = 10) => {
  return text?.slice(0, to) + "...";
};


export const calculateColumns = (apps: AppType[]) => {
  const displayedAppsCount = apps?.length;
  return Math.min(Math.max(displayedAppsCount, 1), 4);
};

export const login = ()=>{
  window.location.href = `${env.VUE_APP_SSO}/login`;
};

export const decodeRouteToken = (token: string): UserType=>{
  const decodedToken: UserType=jwtDecode(token);
  return decodedToken;
};

