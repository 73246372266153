import { defineStore } from "pinia";
import { Decrypt, Encrypt } from "@/services/crypto";


export const useAccessLevelStore = defineStore({
  id: "store_access_level",
  
  state: () => ({
 
    permissions:{
      view_access_level: false,
      manage_access_level: false,
      view_loan_forms: false,
      manage_loan_forms: false,
      view_loan_types: false,
      manage_loan_types: false,
      view_loan_approvers: false,
      manage_loan_approvers: false,
      view_loans_manager: false,
      manage_loans_manager: false,
      view_your_loans: false,
      manage_your_loans: false
    },

  }),

//persist:true
  persist:{
  serializer: {
      deserialize: (value) => JSON.parse(Decrypt(JSON.parse(value))),
      serialize: (value) => JSON.stringify(Encrypt(JSON.stringify(value)))
    }}
});
