import Cookies from "cookies-ts";
import jwt_decode from "jwt-decode";
import { UserType } from "@/types/usersType";

const env = process.env;

const cookies = new Cookies();
const TOKEN = "AUTH_TOKEN";
export function setToken(token: string) {
  if (token) {
    const time = Number(Date.now());
    const decodeToken: UserType = jwt_decode(token);
    const expires =   Number(decodeToken.exp)*1000;
    const expiringTime = Math.floor((expires-time)/1000);
    expiringTime > 0 && cookies.set(TOKEN, token, { expires: expiringTime});
  }
}

export async function logout() {
  cookies.set(TOKEN, null, { expires: -60 * 60 * 8 });
}
export function getToken() {
  return cookies.get(TOKEN);
}

export function isLoggedIn() {
  return getToken();
}

export function getUserid(): string {
  const user: { id: string } = jwt_decode(getToken() as string);
  return user.id;
}

export function encryptUserid(): string {
  const user: { id: string } = jwt_decode(getToken() as string);
  return user.id;
}
export function decodeToken() {
  const decoded: UserType = jwt_decode(getToken() as string);
  return decoded;
}

export async function fetchToken() {
  try {
    const response = await fetch(`${env.VUE_APP_SSO}/login-token`, {
      method: "POST",
      credentials: "include"
    });
    return await response.json();
  } catch (error) {
    return;
  }
}
