import PageNotFound from "../PageNotFound.vue";

export default [
  {
    path: "/manage-loans",
    name: "ManageLoans",
    meta: { auth: true },
    component: () => import("../views/adminView/ManageLoans.vue")
  },
  {
    path: "/loan-details/:id",
    name: "LoanDetails",
    meta: { auth: true },
    component: () => import("../views/adminView/LoanDetails.vue")
  },
  {
    path: "/view-loan-details/:id",
    name: "ViewLoanApplication",
    meta: { auth: true },
    component: () => import("../components/ViewLoanApplication.vue")
  },
  {
    path: "/",
    name: "Loans",
    meta: { auth: true },
    component: () => import("../views/LoanRequestOverview.vue"),
     children: [
      
     ]
  },
  {
    path: "/:catchAll(.*)*",
    name: "PageNotFound",
    meta: { auth: true },
    component: PageNotFound
  },
  {
    path: "/configurations",
    name: "Settings",
    meta: { auth: true },
    component: () =>
      import(/* webpackChunkName: "config" */ "../views/SettingsView.vue"),
    children: [
      {
        path: "/set-loan-approver",
        name: "SetLoanApprover",
        meta: { auth: true },
        component: () =>
          import(
            /* webpackChunkName: "config" */ "../views/adminView/SetLoanApprover.vue"
          )
      },
      {
        path: "/set-loan-forms",
        name: "LoanForms",
        meta: { auth: true },
        component: () =>
          import(
            /* webpackChunkName: "config" */ "../views/adminView/SetLoanForms.vue"
          )
      },
      {
        path: "/loan-type-overview",
        name: "LoanType",
        meta: { auth: true },
        component: () =>
          import(
            /* webpackChunkName: "config" */ "../views/adminView/LoanTypeOverview.vue"
          )
      },
      {
        path: "/accesslevel",
        name: "accessLevel",
        meta: { auth: true },
        component: () =>
          import(
            /* webpackChunkName: "config" */ "../views/AccessLevelPage.vue"
          )
      },
      {
        path: "/accesslevelsub",
        name: "accessLevelsub",
        meta: { auth: true },
        component: () =>
          import(
            /* webpackChunkName: "config" */ "../views/AccessLevelSubPage.vue"
          )
      },
      {
        path: "/loan-policy-term/:url",
        name: "loanpolicyterm",
        meta: { auth: true },
        component: () =>
          import(
            /* webpackChunkName: "config" */ "../views/LoanPolicyTerm.vue"
          )
      },
      {
        path: "/loans-help",
        name: "loanshelp",
        meta: { auth: true },
        component: () =>
          import(
            /* webpackChunkName: "config" */ "../views/HelpPage.vue"
          )
      },
    ]
  }
];
