import { AES, enc } from "crypto-ts";
const env = process.env;
const key = String(env.VUE_APP_ENCRYPTION_KEY);

export function Encrypt(text: string | string[]) {
  return AES.encrypt(text as string, key).toString();
}

export function Decrypt(text: string | string[]) {
  return AES.decrypt(text as string, key).toString(enc.Utf8);
}