import { defineStore } from "pinia";
import { ListLoansAccessLevelResponse } from "../interfaces/accessleveltypes";
import { Decrypt, Encrypt } from "@/services/crypto";


export const useLoansPermissionsStore = defineStore({
  id: "loansPermissions",
  
  state: () => ({
    //access level count
    accesslevelcount: 0,
    accesslevelid:0,
    accessleveleditmode:false,

    //loans permission
    loan_perm_all: false,
    view_loans_manager: false,
    manage_loans_manager: false,
    view_your_loans: false,
    manage_your_loans: false,

    //settings permission
    settings_perm_all: false,
    view_access_level: false,
    manage_access_level: false,
    view_loan_forms: false,
    manage_loan_forms: false,
    view_loan_types: false,
    manage_loan_types: false,
    view_loan_approvers: false,
    manage_loan_approvers: false,

    //Basic information
    accesslevelname: "",
    accessleveldescription: "",

    //assing access level
    assignees: [] as number[],

    //selected assignees
    usersSelected: [] as { full_name: string; user_id: number }[],

    fetchallaccesslevel: [] as ListLoansAccessLevelResponse[],
  }),

  actions: {
     //access level count
      setAcesslevelcount(data:number){
        this.accesslevelcount = data;
      },

      setAccesslevelid(data:number){
       this.accesslevelid = data;
      },

      setAccessleveleditmode(data:boolean){
        this.accessleveleditmode = data;
      },

    // Actions for loans permissions
    setLoanPermAll(data: boolean) {
      this.loan_perm_all = data;
    },
    setViewLoansManager(data: boolean) {
      this.view_loans_manager = data;
    },
    setManageLoansManager(data: boolean) {
      this.manage_loans_manager = data;
    },
    setViewYourLoans(data: boolean){
      this.view_your_loans = data;
    },
    setManageYourLoans(data: boolean){
      this.manage_your_loans = data;
    },

    // Actions for settings permissions
    setSettingsPermAll(data: boolean) {
      this.settings_perm_all = data;
    },
    setViewAccessLevel(data: boolean) {
      this.view_access_level = data;
    },
    setManageAccessLevel(data: boolean) {
      this.manage_access_level = data;
    },
    setViewLoanForms(data: boolean) {
      this.view_loan_forms = data;
    },
    setManageLoanForms(data: boolean) {
      this.manage_loan_forms = data;
    },
    setViewLoanTypes(data: boolean) {
      this.view_loan_types = data;
    },
    setManageLoanTypes(data: boolean) {
      this.manage_loan_types = data;
    },
    setViewLoanApprovers(data: boolean) {
      this.view_loan_approvers = data;
    },
    setManageLoanApprovers(data: boolean) {
      this.manage_loan_approvers = data;
    },

    // Actions for basic information
    setAccessLevelName(name: string) {
      this.accesslevelname = name;
    },
    setAccessLevelDescription(description: string) {
      this.accessleveldescription = description;
    },

    setAssignees(data: number[]) {
      this.assignees = data;
    },

    setUsersSelected(data: { full_name: string; user_id: number }[]) {
      this.usersSelected = data;
    },

    setFetchallaccesslevel(data:ListLoansAccessLevelResponse[]){
      this.fetchallaccesslevel = data;
    }
  },

  persist:{
  serializer: {
      deserialize: (value) => JSON.parse(Decrypt(JSON.parse(value))),
      serialize: (value) => JSON.stringify(Encrypt(JSON.stringify(value)))
    }}
});
